import React from "react"

const Section = ({ children, id, sectionTitle }: { children: React.ReactNode, id: string, sectionTitle: string }) => {
    return (
      <section id={id} className="container body">
        <h1>{sectionTitle}</h1>
        <div
          className="wrapper"
          dangerouslySetInnerHTML={{
            __html: children?.toString() as string,
          }}
        ></div>
      </section>
    )
}

export default Section
