import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Hero from "../components/hero";
import Section from "../components/section";
import Connect from "../components/connect";
import Footer from "../components/footer";
import SEO from "../components/seo";
import About from "../components/About";

// markup
const IndexPage = ({ data }: { data: any }) => {
  return (
    <Layout>
      <SEO title='Home' />
      <Hero />
      <About profileImage={data.profileImage} />
      <Section
        id={data.contentfulPage.section2Title}
        sectionTitle={data.contentfulPage.section2Title}>
        {data.contentfulPage.section2Content.childMarkdownRemark.html}
      </Section>
      <Connect />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    profileImage: file(relativePath: { eq: "josh-newton.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    contentfulPage(pageTitle: { eq: "Home" }) {
      pageTitle
      section1Title
      section2Title
      section1Content {
        childMarkdownRemark {
          html
        }
      }
      section2Content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

export default IndexPage;
