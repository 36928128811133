import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";

export default function Connect() {
  return (
    <section id='Connect' className='container body'>
      <div className='right-triangle'></div>
      <h1 className='fadeInRight'>CONTACT</h1>
      <div className='wrapper'>
        <a
          href='mailto:josh@imjoshnewton.com?Subject=From%20imjoshnewton.com'
          target='_top'>
          {" "}
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
        <a href='tel:412-5674755'>
          {" "}
          <FontAwesomeIcon icon={faPhone} />
        </a>
      </div>
    </section>
  );
}
