import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faFacebookSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer id='footer' className='container footer'>
      <div className='fifty-rect'></div>
      <h1 className='fadeInRight'>SOCIAL</h1>
      <div className='wrapper'>
        <a
          href='https://facebook.com/newtonjd1'
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faFacebookSquare} />
        </a>
        <a
          href='https://twitter.com/imjoshnewton'
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faTwitterSquare} />
        </a>
        <a
          href='https://www.linkedin.com/in/newtonjosh'
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a
          href='https://instagram.com/imjoshnewton'
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href='https://unsplash.com/@imjoshnewton'
          target='_blank'
          rel='noopener noreferrer'>
          <FontAwesomeIcon icon={faCamera} />
        </a>
      </div>
    </footer>
  );
}
