import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Link, animateScroll as scroll } from "react-scroll";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

class Hero extends React.Component<
  { data: any },
  { height1: string; height2: string }
> {
  constructor(props: any) {
    super(props);
    this.state = { height1: "95vh", height2: `calc(${props.height} + 100px)` };
  }

  componentDidMount() {
    this.setState({
      height1: window.innerHeight + "px",
      height2: window.innerHeight + 100 + "px",
    });
  }

  render() {
    const styles: any = {
      header: {
        height: this.state.height1,
      },
      bg: {
        height: this.state.height2,
        position: "fixed",
        opacity: "0",
        willChange: "opacity",
      },
      overlayContainer: {
        position: "absolute",
        zIndex: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background:
          "linear-gradient(rgba(21, 21, 21, 0.6), rgba(21, 21, 21, 0.6))",
      },
    };

    const bgImage1 = getImage(this.props.data.bgImage1),
      bgImage2 = getImage(this.props.data.bgImage2),
      bgImage3 = getImage(this.props.data.bgImage3),
      bgImage4 = getImage(this.props.data.bgImage4),
      bgImage5 = getImage(this.props.data.bgImage5);

    return (
      <header id='header' style={styles.header} className='container header'>
        <GatsbyImage
          image={bgImage1 as IGatsbyImageData}
          alt='A dinosaur'
          style={styles.bg}
          className='bg'
        />
        <GatsbyImage
          image={bgImage2 as IGatsbyImageData}
          alt='A dinosaur'
          style={styles.bg}
          className='bg'
        />
        <GatsbyImage
          image={bgImage3 as IGatsbyImageData}
          alt='A dinosaur'
          style={styles.bg}
          className='bg'
        />
        <GatsbyImage
          image={bgImage4 as IGatsbyImageData}
          alt='A dinosaur'
          style={styles.bg}
          className='bg'
        />
        <GatsbyImage
          image={bgImage5 as IGatsbyImageData}
          alt='A dinosaur'
          style={styles.bg}
          className='bg'
        />

        <div style={styles.overlayContainer}>
          <div className='animated fadeInUp header__title-wrapper'>
            <p className='header___title-pre'>Hi,</p>
            <h1 className='header__title'>I'M JOSH NEWTON</h1>

            <p className='header__sub-title'>
              <Link to='About' className='header__link' smooth={true}>
                About
              </Link>
              <span>&nbsp;-&nbsp;</span>
              <Link to='Skills' className='header__link' smooth={true}>
                Skills
              </Link>
              {/* <span>&nbsp;-&nbsp;</span>
              <Link to="/#Blog" className="header__link">
                Blog
              </Link> */}
              <span>&nbsp;-&nbsp;</span>
              <Link to='Connect' className='header__link' smooth={true}>
                Connect
              </Link>
            </p>
          </div>
        </div>
      </header>
    );
  }
}

export default (props: any) => (
  <StaticQuery
    query={graphql`
      {
        bgImage1: file(relativePath: { eq: "chicago-skyline.jpeg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        bgImage2: file(relativePath: { eq: "parking-meeters.jpeg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        bgImage3: file(relativePath: { eq: "golden-sunrise.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        bgImage4: file(relativePath: { eq: "black-and-white-windows.jpeg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        bgImage5: file(relativePath: { eq: "canon-film-camera.jpeg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `}
    render={(data) => <Hero data={data} {...props} />}
  />
);
