import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React from "react";

export default function About({
  profileImage,
}: {
  profileImage: ImageDataLike;
}) {
  const profileImageData = getImage(profileImage);

  return (
    <section
      id='About'
      className='container body flex wrap flex-row gap-5 items-center'>
      <div className='image-wrapper'>
        <GatsbyImage
          image={profileImageData!}
          alt='A dinosaur'
          className='rounded-full'
        />
      </div>
      <div className='content-wrapper'>
        <h1>ABOUT</h1>
        <div className='wrapper'>
          <p>I'm Josh Newton.</p>
          <p>
            I'm a developer, marketer, designer, trainer, assessment expert,
            consultant, photographer, husband, and father from Claysville, PA.
          </p>
          <p>
            I currently work as the Director of Technology for the Newton
            Institute, a family owned and opperated business, in the greater
            Pittsburgh area.
          </p>
          <p>
            Along with being responsible for the technical needs of the company,
            I work with clients that want to better align their employees'
            natural abilities and talents with their work. I love watching
            people flourish through self-awareness and authenticity to achieve
            the purpose of their organization.
          </p>
          <p>
            I have a BS in Computer Information Systems from Grove City College
            and enjoy bringing ideas to life through web design and web app
            development.
          </p>
          <p>
            I also love spending time with my wife and five kids. they are the
            motivation behind everything i do.
          </p>
        </div>
      </div>
    </section>
  );
}
